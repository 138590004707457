var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _J18Next_i18next, _J18Next_supportedLanguages, _J18Next_resources;
import i18next from "i18next";
class J18Next {
    constructor({ languages, translations }, LanguageDetector = null) {
        _J18Next_i18next.set(this, void 0);
        _J18Next_supportedLanguages.set(this, void 0);
        _J18Next_resources.set(this, void 0);
        this.init = (settings = {}) => __awaiter(this, void 0, void 0, function* () {
            yield __classPrivateFieldGet(this, _J18Next_i18next, "f").init(Object.assign({ resources: __classPrivateFieldGet(this, _J18Next_resources, "f"), fallbackLng: __classPrivateFieldGet(this, _J18Next_supportedLanguages, "f"), supportedLngs: __classPrivateFieldGet(this, _J18Next_supportedLanguages, "f"), interpolation: {
                    escapeValue: false // react already safes from xss
                } }, settings));
        });
        this.onLanguageChanged = (callback) => __classPrivateFieldGet(this, _J18Next_i18next, "f").on("languageChanged", callback);
        this.offLanguageChanged = (callback) => __classPrivateFieldGet(this, _J18Next_i18next, "f").off("languageChanged", callback);
        this.setLanguage = (language) => __classPrivateFieldGet(this, _J18Next_i18next, "f").changeLanguage(language);
        this.getLanguage = () => __classPrivateFieldGet(this, _J18Next_i18next, "f").language;
        this.getLanguages = () => __classPrivateFieldGet(this, _J18Next_i18next, "f").languages;
        this.translate = (text) => __classPrivateFieldGet(this, _J18Next_i18next, "f").t(text);
        __classPrivateFieldSet(this, _J18Next_i18next, i18next.createInstance(), "f");
        LanguageDetector && __classPrivateFieldGet(this, _J18Next_i18next, "f").use(LanguageDetector);
        __classPrivateFieldSet(this, _J18Next_supportedLanguages, languages, "f");
        __classPrivateFieldSet(this, _J18Next_resources, {}, "f");
        for (const language of languages) {
            __classPrivateFieldGet(this, _J18Next_resources, "f")[language] = { translation: {} };
        }
        const languageEntries = [...languages.entries()];
        for (const [dev, trans] of Object.entries(translations)) {
            for (const [index, language] of languageEntries) {
                //@ts-ignore
                __classPrivateFieldGet(this, _J18Next_resources, "f")[language].translation[dev] = trans[index];
            }
        }
    }
    get language() { return __classPrivateFieldGet(this, _J18Next_i18next, "f").language; }
    get languages() { return __classPrivateFieldGet(this, _J18Next_i18next, "f").languages; }
    get supportedLanguages() { return __classPrivateFieldGet(this, _J18Next_supportedLanguages, "f"); }
}
_J18Next_i18next = new WeakMap(), _J18Next_supportedLanguages = new WeakMap(), _J18Next_resources = new WeakMap();
export default J18Next;
