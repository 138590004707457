var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, privateMap, value) {
    if (!privateMap.has(receiver)) {
        throw new TypeError("attempted to set private field on non-instance");
    }
    privateMap.set(receiver, value);
    return value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, privateMap) {
    if (!privateMap.has(receiver)) {
        throw new TypeError("attempted to get private field on non-instance");
    }
    return privateMap.get(receiver);
};
var _title, _intro, _chapters, __id, _parts, __id_1, _header, _intro_1, _sections, __id_2, _header_1, _parts_1, __id_3, _type;
import JJoi from "jjoi";
// --> description of raw data
const courseDataPartObjectSchema = JJoi.object().required().keys({
    _id: JJoi.string().required(),
    type: JJoi.string().required(),
});
const courseDataSectionObjectSchema = JJoi.object().required().keys({
    _id: JJoi.string().required(),
    header: JJoi.string().required(),
    parts: JJoi.array().items(courseDataPartObjectSchema).empty()
});
const courseDataIntroObjectSchema = JJoi.object().required().keys({
    _id: JJoi.string().required().allow(""),
    parts: JJoi.array().items(courseDataPartObjectSchema).empty()
});
const courseDataChapterObjectSchema = JJoi.object().required().keys({
    _id: JJoi.string().required(),
    header: JJoi.string().required(),
    intro: JJoi.alternatives().try(courseDataSectionObjectSchema, JJoi.allow(null)),
    sections: JJoi.array().items(courseDataSectionObjectSchema).empty(JJoi.array().length(0))
});
const courseDataObjectSchema = JJoi.object().required().keys({
    title: JJoi.string().required().allow(""),
    intro: JJoi.alternatives().try(courseDataIntroObjectSchema, JJoi.allow(null)),
    chapters: JJoi.array().items(courseDataChapterObjectSchema).empty(JJoi.array().length(0)),
});
// <-- description of raw data
class CourseData {
    constructor(courseDataObject) {
        _title.set(this, void 0);
        _intro.set(this, void 0);
        _chapters.set(this, void 0);
        JJoi.assert(courseDataObject, courseDataObjectSchema);
        const { title, intro, chapters } = courseDataObject;
        __classPrivateFieldSet(this, _title, title);
        __classPrivateFieldSet(this, _intro, intro ? new CourseDataIntro(intro) : null);
        __classPrivateFieldSet(this, _chapters, chapters.map((chapter) => new CourseDataChapter(chapter)));
    }
    ;
    get title() { return __classPrivateFieldGet(this, _title); }
    ;
    get intro() { return __classPrivateFieldGet(this, _intro); }
    get chapters() { return __classPrivateFieldGet(this, _chapters); }
    ;
    get _object() {
        const course = {
            title: __classPrivateFieldGet(this, _title),
            intro: __classPrivateFieldGet(this, _intro)._object,
            chapters: __classPrivateFieldGet(this, _chapters).map((chapter) => chapter._object),
        };
        return course;
    }
    ;
}
_title = new WeakMap(), _intro = new WeakMap(), _chapters = new WeakMap();
;
class CourseDataIntro {
    constructor(courseDataSectionObject) {
        __id.set(this, void 0);
        _parts.set(this, void 0);
        const { _id, parts } = courseDataSectionObject;
        __classPrivateFieldSet(this, __id, _id);
        __classPrivateFieldSet(this, _parts, parts.map((part) => new CourseDataPart(part)));
        ;
    }
    get _id() { return __classPrivateFieldGet(this, __id); }
    ;
    get parts() { return __classPrivateFieldGet(this, _parts); }
    ;
    get _object() {
        const chapter = {
            _id: __classPrivateFieldGet(this, __id),
            parts: __classPrivateFieldGet(this, _parts).map((part) => part._object),
        };
        return chapter;
    }
    ;
}
__id = new WeakMap(), _parts = new WeakMap();
class CourseDataChapter {
    constructor(courseDataChapterObject) {
        __id_1.set(this, void 0);
        _header.set(this, void 0);
        _intro_1.set(this, void 0);
        _sections.set(this, void 0);
        const { _id, header, intro, sections } = courseDataChapterObject;
        __classPrivateFieldSet(this, __id_1, _id);
        __classPrivateFieldSet(this, _header, header);
        __classPrivateFieldSet(this, _intro_1, intro ? new CourseDataSection(intro) : null);
        __classPrivateFieldSet(this, _sections, sections.map((part) => new CourseDataSection(part)));
        ;
    }
    get _id() { return __classPrivateFieldGet(this, __id_1); }
    ;
    get header() { return __classPrivateFieldGet(this, _header); }
    ;
    get intro() { return __classPrivateFieldGet(this, _intro_1); }
    ;
    get sections() { return __classPrivateFieldGet(this, _sections); }
    ;
    get _object() {
        const chapter = {
            _id: __classPrivateFieldGet(this, __id_1),
            header: __classPrivateFieldGet(this, _header),
            intro: __classPrivateFieldGet(this, _intro_1)._object,
            sections: __classPrivateFieldGet(this, _sections).map((section) => section._object),
        };
        return chapter;
    }
    ;
}
__id_1 = new WeakMap(), _header = new WeakMap(), _intro_1 = new WeakMap(), _sections = new WeakMap();
class CourseDataSection {
    constructor(courseDataSectionObject) {
        __id_2.set(this, void 0);
        _header_1.set(this, void 0);
        _parts_1.set(this, void 0);
        const { _id, header, parts } = courseDataSectionObject;
        __classPrivateFieldSet(this, __id_2, _id);
        __classPrivateFieldSet(this, _header_1, header);
        __classPrivateFieldSet(this, _parts_1, parts.map((part) => new CourseDataPart(part)));
        ;
    }
    get _id() { return __classPrivateFieldGet(this, __id_2); }
    ;
    get header() { return __classPrivateFieldGet(this, _header_1); }
    get parts() { return __classPrivateFieldGet(this, _parts_1); }
    ;
    get _object() {
        const chapter = {
            _id: __classPrivateFieldGet(this, __id_2),
            header: __classPrivateFieldGet(this, _header_1),
            parts: __classPrivateFieldGet(this, _parts_1).map((part) => part._object),
        };
        return chapter;
    }
    ;
}
__id_2 = new WeakMap(), _header_1 = new WeakMap(), _parts_1 = new WeakMap();
class CourseDataPart {
    constructor(courseDataPartObject) {
        __id_3.set(this, void 0);
        _type.set(this, void 0);
        const { _id, type } = courseDataPartObject;
        __classPrivateFieldSet(this, __id_3, _id);
        __classPrivateFieldSet(this, _type, type);
    }
    get _id() { return __classPrivateFieldGet(this, __id_3); }
    ;
    get type() { return __classPrivateFieldGet(this, _type); }
    get _object() {
        const part = {
            _id: __classPrivateFieldGet(this, __id_3),
            type: __classPrivateFieldGet(this, _type),
        };
        return part;
    }
    ;
}
__id_3 = new WeakMap(), _type = new WeakMap();
const emptyCourse = new CourseData({ title: "", intro: { _id: "", header: "", parts: [] }, chapters: [] });
export default CourseData;
export { CourseDataChapter, CourseDataIntro, CourseDataSection, CourseDataPart, emptyCourse };
