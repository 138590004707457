import { useContext,useRef } from 'react';
import { AppLayoutStyleContext } from '../../Contexts';
import useElementDimensions from '../../common/useElementDimensions';
import NavbarPositionContextProvider from './navbarPositionContext';
import NavBarAction from './NavBarAction';
import NavBarLink from './NavBarLink';
import NavBarCollapsible from './NavBarCollapsible';
import NavBarDropdown from './dropdown/Dropdown';
import type { itemList } from "./common";
import "./NavBar.scss";



const NavBar = ({ itemLists, position }: { itemLists: { _id: number, itemList: itemList }[], position: string }) => {
	const self = useRef(null);
	const layoutStyle = useContext(AppLayoutStyleContext);
	const [ width,height ] = useElementDimensions(self)

	return (
		<nav ref={self} className={`japp-navbar japp-navbar-${layoutStyle}`}>
			<NavbarPositionContextProvider navbarPositionAndDimensions={[position,width,height]}>
				{itemLists.map(({ _id,itemList }) => (
					itemList
					&&
					<ul className={`japp-navbar-items japp-navbar-items-${layoutStyle}`} key={_id}>
						{itemList.map(({ _id, Type, data }) => <Type key={_id} {...data} />)}
					</ul>
				))}
			</NavbarPositionContextProvider>
		</nav>
	);
};



export default NavBar;
export { NavBarAction, NavBarCollapsible, NavBarLink, NavBarDropdown };
