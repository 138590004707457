import { NavLink } from 'react-router-dom';
import { NavBarItemContent, _symbol } from "./common";



type navBarLinkTypes = { to:string,symbol:_symbol,text:string };

const NavBarLink = ({ to,symbol,text }:navBarLinkTypes) => (
	<li className="japp-navbar-items-item">
		<NavLink to={to}>
			<NavBarItemContent symbol={symbol} text={text} />
		</NavLink>
	</li>	
);



export default NavBarLink;
