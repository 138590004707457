var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, privateMap, value) {
    if (!privateMap.has(receiver)) {
        throw new TypeError("attempted to set private field on non-instance");
    }
    privateMap.set(receiver, value);
    return value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, privateMap) {
    if (!privateMap.has(receiver)) {
        throw new TypeError("attempted to get private field on non-instance");
    }
    return privateMap.get(receiver);
};
var _theory;
import JJoi from "jjoi";
const coursePartTheoryObjectSchema = JJoi.object().required().keys({
    t: JJoi.string().required(),
});
class CoursePartTheory {
    constructor(coursePartTheoryObject) {
        _theory.set(this, void 0); // html string
        JJoi.assert(coursePartTheoryObject, coursePartTheoryObjectSchema);
        const { t } = coursePartTheoryObject;
        __classPrivateFieldSet(this, _theory, t);
    }
    get theory() { return __classPrivateFieldGet(this, _theory); }
}
_theory = new WeakMap();
export default CoursePartTheory;
