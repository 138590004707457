import Cookies from 'universal-cookie';
import { User,noUser } from "juser";
import type HttpService from "jhttp-client";



type onUserCallback = (user:User) => void;
type onTermsAcceptedCallback = (termsAccepted:boolean) => void;

class UserService {
	#httpService:HttpService;
	#currentUser:User;
	#onUserCallbacks:Set<onUserCallback>;
	#onTermsAcceptedCallbacks:Set<onTermsAcceptedCallback>;

	constructor() {
		this.#currentUser = noUser;
		this.#onUserCallbacks = new Set();
		this.#onTermsAcceptedCallbacks = new Set();
	};

	init = (httpService:HttpService) => {
		this.#httpService = httpService;
		this.#restoreUser();
	};

	get currentUser() {return this.#currentUser};
	get user() {return this.#currentUser};
	get termsAccepted() {return this._termsAccepted()}

	// --> Terms accepted
	#emitTermsAccepted = () => {
		const termsAccepted = this.termsAccepted;
		this.#onTermsAcceptedCallbacks.forEach((callback) => callback(termsAccepted));
	};
	_termsAccepted = () => this.#currentUser._id || window.localStorage.getItem("termsAccepted") ? true : false;
	setTermsAccepted = (areThey:boolean) => {
		window.localStorage.setItem("termsAccepted",`${areThey}`);
		this.#emitTermsAccepted();
	};
	onTermsAccepted = (callback:onTermsAcceptedCallback) => this.#onTermsAcceptedCallbacks.add(callback);
	offTermsAccepted = (callback:onTermsAcceptedCallback) => this.#onTermsAcceptedCallbacks.delete(callback);
	// <-- Terms accepted

	// TODO for register,login, logout: throw errors instead of returning them
	register = async({ email,name,password }:{ email:string,name:string,password:string }) => {
		await this.#httpService.post("/api/user/register",{ email,name,password });
	};
	
	login = async({ email,password }:{ email:string,password:string }) => {
		// @ts-ignore
		await this.#httpService.post("/api/user/login",{ email,password });
		//const sessionToken = headers["x-auth-token"];
		// @ts-ignore
		const { data } = await this.#httpService.get("/api/user/current");
		const user = new User(data);
		this.#setUser(user);
	};
	
	registerAndLogin = async({ email,name,password }:{ email:string,name:string,password:string }) => {
		await this.register({ email,name,password });
		await this.login({ email,password });
	};
	
	logout = async() => {
		// notice that posting logout is not awaited (on purpose)
		try {
			this.#httpService.post("/api/user/logout",true);
		} catch(error:any) {}
		this.#resetUser();
	};

	// -->
	// Keep in-memry user data and localStorage in sync

	// used when login is called
	#setUser = (user:User) => {
		this.#currentUser = user;
		this.#storeUserToLocalStorage();
		this.#emitUserChange();
	};

	// used when logout is called
	#resetUser = () => {
		const cookies = new Cookies();
		cookies.remove("sessionToken");
		this.#currentUser = noUser;
		this.#resetUserLocalStorage();
		this.#emitUserChange();
	};

	// used when module is reloaded
	#restoreUser = async() => {
		if(
			!window.localStorage.getItem("user")
		)
			this.#resetUser();
		else {
			// check that session is still open
			const [ response,error ] = await this.#httpService.get("/api/user/current");
			if(!response) {
				this.#retrieveUserFromLocalStorage();
				this.#emitUserChange();
				return;
			}
			if(error) {
				if(response && response.status === 401) {
					this.#resetUser();
					return;
				}
				throw error;
			}
			this.#setUser(response.data.user)
		}
	};

	// --> whenever user changes
	onUserChanged = (callback:onUserCallback) => this.#onUserCallbacks.add(callback);
	offUserChanged = (callback:onUserCallback) => this.#onUserCallbacks.delete(callback);
	#emitUserChange = () => this.#onUserCallbacks.forEach((callback) => callback(this.#currentUser));
	// <--

	// Technically naming conventions of this part could cause conflicts in unlikely scenarios
	#storeUserToLocalStorage = () => {
		window.localStorage.setItem("user",JSON.stringify(this.#currentUser._object));
	};
	
	#resetUserLocalStorage = () => {
		window.localStorage.setItem("user","");
	};
	
	#retrieveUserFromLocalStorage = () => {
		const _user = window.localStorage.getItem("user");
		this.#currentUser = _user ? new User(JSON.parse(_user)) : noUser;
	};
	// <--
}



export default UserService;
