import { useState,useEffect } from 'react';
import { toast } from "react-toastify";
import JCourses from '../core/jCourses';



const jCourses = new JCourses();
jCourses.init();
const { setLanguage,setTermsAcceptedTrue,login,logout,registerAndLogin,translate,courses,notifications } = jCourses;
const t = (text:string) => translate(text);



const useLanguage = () => {
	const [ language,_setLanguage ] = useState(jCourses.language);

	useEffect(() => {
		jCourses.onLanguageChanged(_setLanguage);
		return () => { jCourses.offLanguageChanged(_setLanguage) };
	},[]);

	return language;
};

const useUser = () => {
	const [ user,setUser ] = useState(jCourses.user);

	useEffect(() => {
		jCourses.onUserChanged(setUser);
		return () => { jCourses.offUserChanged(setUser) };
	},[]);

	return user;
};

const useTermsAccepted = () => {
	const [ termsAccepted,setTermsAccepted ] = useState(jCourses.termsAccepted);

	useEffect(() => {
		jCourses.onTermsAccepted(setTermsAccepted);
		return () => { jCourses.offTermsAccepted(setTermsAccepted) };
	},[]);

	return termsAccepted;
}



notifications.onNotification(toast.info);
notifications.onWarning(toast.warning);
const showNotification = (message:string) => notifications.showNotification(message);
const showWarning = (message:string) => notifications.showWarning(message);

export { useLanguage,useUser,useTermsAccepted,setTermsAcceptedTrue,setLanguage,registerAndLogin,login,logout,t,courses,showNotification,showWarning };
