import { createContext } from 'react';



const NavbarContext = createContext<any>(null);
NavbarContext.displayName = "NavbarContext";

const AppLayoutStyleContext = createContext<string>("narrow");
AppLayoutStyleContext.displayName = "AppLayoutStyleContext";

const SidebarShowContext = createContext<any>(null);
SidebarShowContext.displayName = "SidebarShowContext";


const UIContexts = ({ setNavbarCustomItems,appLayoutStyle,children,showSidebar }:any) => (
	<NavbarContext.Provider value={setNavbarCustomItems}>
		<AppLayoutStyleContext.Provider value={appLayoutStyle}>
			<SidebarShowContext.Provider value={showSidebar}>
				{children}
			</SidebarShowContext.Provider>
		</AppLayoutStyleContext.Provider>
	</NavbarContext.Provider>
);



export default UIContexts;
export { NavbarContext,AppLayoutStyleContext,SidebarShowContext };
