import { createContext } from 'react';



const NavBarPositionContext = createContext<any>(null);
NavBarPositionContext.displayName = "NavBarPositionContext";



const NavbarPositionContextProvider = ({ navbarPositionAndDimensions,children }: any) => (
	<NavBarPositionContext.Provider value={navbarPositionAndDimensions}>
		{children}
	</NavBarPositionContext.Provider>
);



export default NavbarPositionContextProvider;
export { NavBarPositionContext };
