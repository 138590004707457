var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, privateMap, value) {
    if (!privateMap.has(receiver)) {
        throw new TypeError("attempted to set private field on non-instance");
    }
    privateMap.set(receiver, value);
    return value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, privateMap) {
    if (!privateMap.has(receiver)) {
        throw new TypeError("attempted to get private field on non-instance");
    }
    return privateMap.get(receiver);
};
var _id_1, _name, _iconFileName;
import JJoi from "jjoi";
// --> description of raw data
const courseObjectSchema = JJoi.object().required().keys({
    _id: JJoi.string().required().allow(""),
    iconFileName: JJoi.string().required().allow(""),
    name: JJoi.string().required().allow(""),
}).options({ allowUnknown: true });
// <-- description of raw data
class Course {
    constructor(courseObject) {
        _id_1.set(this, void 0);
        _name.set(this, void 0);
        _iconFileName.set(this, void 0);
        JJoi.assert(courseObject, courseObjectSchema);
        const { _id, iconFileName, name } = courseObject;
        __classPrivateFieldSet(this, _id_1, _id);
        __classPrivateFieldSet(this, _name, name);
        __classPrivateFieldSet(this, _iconFileName, iconFileName);
    }
    ;
    get _id() { return __classPrivateFieldGet(this, _id_1); }
    ;
    get iconFileName() { return __classPrivateFieldGet(this, _iconFileName); }
    ;
    get name() { return __classPrivateFieldGet(this, _name); }
    //get thumbnails() {return this.#thumbnails};
    get _object() {
        const course = {
            _id: __classPrivateFieldGet(this, _id_1),
            name: __classPrivateFieldGet(this, _name),
            iconFileName: __classPrivateFieldGet(this, _iconFileName),
        };
        return course;
    }
    ;
}
_id_1 = new WeakMap(), _name = new WeakMap(), _iconFileName = new WeakMap();
;
const noCourse = new Course({ _id: "", iconFileName: "", name: "" });
export default Course;
export { noCourse };
