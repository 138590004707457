var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, privateMap, value) {
    if (!privateMap.has(receiver)) {
        throw new TypeError("attempted to set private field on non-instance");
    }
    privateMap.set(receiver, value);
    return value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, privateMap) {
    if (!privateMap.has(receiver)) {
        throw new TypeError("attempted to get private field on non-instance");
    }
    return privateMap.get(receiver);
};
var _id_1, _name, _email, _admin;
import JJoi from "jjoi";
const userNameSchema = JJoi.string().required().min(3).label("name");
const userEmailSchema = JJoi.string().required().email({ tlds: { allow: false } }).label("email");
const userObjectSchema = JJoi.object().required().keys({
    _id: JJoi.objectId().required(),
    name: userNameSchema,
    email: userEmailSchema,
    admin: JJoi.boolean(),
}).options({ allowUnknown: true });
class User {
    constructor(userObject) {
        _id_1.set(this, void 0);
        _name.set(this, void 0);
        _email.set(this, void 0);
        _admin.set(this, void 0);
        const { _id, name, email, admin = false } = userObject;
        if (!(_id === "" && name === "" && email === ""))
            JJoi.assert(userObject, userObjectSchema);
        __classPrivateFieldSet(this, _id_1, _id);
        __classPrivateFieldSet(this, _name, name);
        __classPrivateFieldSet(this, _email, email);
        __classPrivateFieldSet(this, _admin, admin);
    }
    ;
    static get noUser() { return noUser; }
    ;
    get _id() { return __classPrivateFieldGet(this, _id_1); }
    ;
    get name() { return __classPrivateFieldGet(this, _name); }
    ;
    get email() { return __classPrivateFieldGet(this, _email); }
    ;
    get admin() { return __classPrivateFieldGet(this, _admin); }
    ;
    get _object() {
        const userObject = {
            _id: __classPrivateFieldGet(this, _id_1),
            name: __classPrivateFieldGet(this, _name),
            email: __classPrivateFieldGet(this, _email),
            admin: __classPrivateFieldGet(this, _admin),
        };
        return userObject;
    }
    ;
}
_id_1 = new WeakMap(), _name = new WeakMap(), _email = new WeakMap(), _admin = new WeakMap();
User.validateEmail = (email) => {
    const { error } = JJoi.validate(email, userEmailSchema);
    if (error)
        return { error: error.message };
    return {};
};
User.validateName = (name) => {
    const { error } = JJoi.validate(name, userNameSchema);
    if (error)
        return { error: error.message };
    return {};
};
;
const noUser = new User({ _id: "", name: "", email: "" });
export default User;
export { noUser };
