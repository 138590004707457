import { NavLink } from 'react-router-dom';
import { NavBarItemContent } from "./common";



const NavBarAction = ({ onClick,symbol,text }:{ onClick:any,symbol:{ src:string,alt:string },text:string }) => (
	<li className="japp-navbar-items-item">
		<NavLink to="#" onClick={onClick}>
			<NavBarItemContent symbol={symbol} text={text} />
		</NavLink>
	</li>
);



export default NavBarAction;
