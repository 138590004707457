
type _symbol = { src:string,alt:string };
type item = { _id:number,Type:any,data:any };
type itemList = item[];
type navBarItemContentTypes = { symbol?:_symbol,text?:string };

const NavBarItemContent = ({ symbol,text }:navBarItemContentTypes) => (
	<>
		{symbol && <img src={symbol.src} alt={symbol.alt} height={30}/>}
		{text || null}
	</>
);



export { NavBarItemContent };
export type { itemList,_symbol }
