var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _JHttpClient_baseURL, _JHttpClient_axios, _JHttpClient_handleAxiosError;
import axios from "axios";
class JHttpClient {
    constructor({ url, port, path } = {}) {
        _JHttpClient_baseURL.set(this, void 0);
        _JHttpClient_axios.set(this, void 0);
        this.init = () => __awaiter(this, void 0, void 0, function* () {
        });
        this.get = (path, { headers, responseType } = {}) => __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield __classPrivateFieldGet(this, _JHttpClient_axios, "f").get(path, { headers, responseType });
                return [response, null];
            }
            catch (error) {
                return __classPrivateFieldGet(this, _JHttpClient_handleAxiosError, "f").call(this, error);
            }
        });
        this.post = (path, payload, { headers, responseType } = {}) => __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield __classPrivateFieldGet(this, _JHttpClient_axios, "f").post(path, payload, { headers, responseType });
                return [response, null];
            }
            catch (error) {
                return __classPrivateFieldGet(this, _JHttpClient_handleAxiosError, "f").call(this, error);
            }
        });
        this.put = (path, payload, { headers, responseType } = {}) => __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield __classPrivateFieldGet(this, _JHttpClient_axios, "f").put(path, payload, { headers, responseType });
                return [response, null];
            }
            catch (error) {
                return __classPrivateFieldGet(this, _JHttpClient_handleAxiosError, "f").call(this, error);
            }
        });
        _JHttpClient_handleAxiosError.set(this, (error) => {
            if (error.response) {
                if (error.response.status === 504) {
                    return [null, error];
                }
                return [error.response, error];
            }
            else if (error.request) {
                return [null, error];
            }
            throw error;
        });
        const { location: { hostname, protocol, port: _port } } = window;
        const hostPart = `${protocol}//${hostname}`;
        __classPrivateFieldSet(this, _JHttpClient_baseURL, `${url ? url : hostPart}:${port ? port : _port}${path ? path : "/"}`, "f");
        __classPrivateFieldSet(this, _JHttpClient_axios, axios.create({ baseURL: __classPrivateFieldGet(this, _JHttpClient_baseURL, "f") }), "f");
    }
    ;
}
_JHttpClient_baseURL = new WeakMap(), _JHttpClient_axios = new WeakMap(), _JHttpClient_handleAxiosError = new WeakMap();
;
export default JHttpClient;
/*

axios.get('/user/12345')
  .catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  });

*/ 
