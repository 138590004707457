import { useState,useRef,useEffect,useContext,useLayoutEffect } from 'react';
import { Routes,Route,Navigate,BrowserRouter,Outlet } from 'react-router-dom';

import { toast,ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"

import { t,useLanguage,setLanguage,useTermsAccepted,setTermsAcceptedTrue } from './jCoursesReact';
import UIContexts,{ AppLayoutStyleContext } from './Contexts';
import useElementDimensions from './common/useElementDimensions';
import NavBar,{ NavBarLink,NavBarAction,NavBarCollapsible } from "./components/navBar/NavBar";

import "./App.scss";
import "./components/content/courses/Courses.scss";
import Courses from './components/content/courses/Courses';
import CourseCatalog from './components/content/courses/Catalog';
//import MyCourses from './components/content/courses/MyCourses';
import Course from './components/content/courses/Course';
import NotFound from './NotFound';

//import englishFlag from "../static/en.svg";
//import finnishFlag from "../static/fi.svg";
import menuIcon from "../static/menu.svg";



const App = () => {
	const self = useRef<HTMLDivElement>(null);
	const [ width,height ] = useElementDimensions(self);
	const [ layoutStyle,setLayoutStyle ] = useState<string>("narrow");
	const [ navbarCustomItems,setNavbarCustomItems ] = useState([]);
	const [ xenableSideToggler,setEnableSideToggler ] = useState<boolean>(false);
	const [ showSidebar,setShowsidebar ] = useState<boolean>(false);

	const toggleSidebar = () => setShowsidebar(layoutStyle === "large" ? false : !showSidebar);
	const enableSideToggler = () => {
		setEnableSideToggler(true);
		setShowsidebar(false);
	}
	const disableSideToggler = () => {
		setEnableSideToggler(false);
		setShowsidebar(false);
	}

	const handleResize = () => {
		const layoutStyle = height < 600 ?
			width < 840 ? (width / height < 1.5 ? "narrow" : "short") : "short"
			:
			width < 840 ? "narrow" : "large";
		setLayoutStyle(layoutStyle);
	};

	useLayoutEffect(() => {
		handleResize();
	}, [width,height])

	return (
		<div ref={self} className="app">
			<ToastContainer autoClose={20000} position={toast.POSITION.BOTTOM_RIGHT} />
			<UIContexts setNavbarCustomItems={{ setNavbarCustomItems, enableSideToggler, disableSideToggler, setShowsidebar }} showSidebar={showSidebar} appLayoutStyle={layoutStyle}>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Navigate to="/app" />}/>
						<Route path="/app" element={<Window sidebarEnabled={xenableSideToggler} toggleSidebar={toggleSidebar} navbarCustomItems={navbarCustomItems} />}>
							<Route index element={<CourseCatalog />}/>
							<Route path="course/:_id" element={<Course />}/>
							<Route path="notfound" element={<NotFound />}/>
							<Route path="*" element={<Navigate to="notfound" />}/>
						</Route>
						<Route path="/*" element={<NotFound />}/>
					</Routes>
				</BrowserRouter>
			</UIContexts>
		</div>
	);
}



const Window = ({ sidebarEnabled, toggleSidebar, navbarCustomItems }: any) => {
	const layoutStyle = useContext(AppLayoutStyleContext);
	const [ navBarItems,setNavBarItems ] = useState<any[]>([]);
	const [ navbarPosition,setNavbarPosition ] = useState<string>("top");
	const termsAccepted = useTermsAccepted();
	const language = useLanguage();

	useEffect(() => {
		setNavBarItems(
			[
				{ _id:111,Type:NavBarLink,data:{ to:"https://jarnontunnit.com",text:"jarnontunnit.com" } },
				{ _id:140,Type:NavBarLink,data:{ to:"/app",text:t("Courses") } },
				/*
				{
					_id : 120,
					Type : NavBarCollapsible,
					data : {
						itemList : [
							{ _id:140,Type:NavBarLink,data:{ to:"/courses",text:t("Courses") } },
							//{ _id:160,Type:NavBarAction,data:{ onClick:() => setLanguage("fi"),symbol:{ src:finnishFlag,alt:"Suomeksi/Finnish"} } },
							//{ _id:170,Type:NavBarAction,data:{ onClick:() => setLanguage("en"),symbol:{ src:englishFlag,alt:"English/Englanniksi"} } },
						],
						symbol : { src:menuIcon,alt:"Menu"},
					},
				},
				*/
			]
		);
	},[language]);

	const toggleSidebarButton = [
		{
			_id : 301,
			Type : NavBarAction,
			data : {
				onClick : () => {
					toggleSidebar();
				},
				symbol : { src:menuIcon,alt:"Sisällysluettelo" },
			}
		},
	]

	// Todo: if language changes update text inside toast
	// --> for example close previous toast and open new one
	useEffect(() => {
		if (!termsAccepted) {
			const CloseButton = () => <button onClick={() => closeAcceptTerms()}>OK</button>
			const toastId = toast.info(t("$ terms"), {
				autoClose : false,
				closeOnClick : false,
				closeButton : CloseButton,
			});
			const closeAcceptTerms = () => {
				setTermsAcceptedTrue();
				toast.dismiss(toastId);
			};
		}
	}, [language]);

	useLayoutEffect(() => {
		const navbarPosition = layoutStyle === "short" ? "left" : "top";
		setNavbarPosition(navbarPosition);
	}, [layoutStyle]);

	return (
		<>
			<NavBar position={navbarPosition} itemLists={[...((layoutStyle !== "large" && sidebarEnabled) ? [{ _id: 300, itemList: toggleSidebarButton }] : []), { _id: 100, itemList: navBarItems }, { _id: 200, itemList: navbarCustomItems }]} />
			<div className={`japp-content japp-content-${layoutStyle}`}>
				<div className="courses">
					<Outlet />
				</div>
			</div>

		</>
	);
};



export default App;

/**

 */