/**
 * Upstream notifications
 * Application core can send notifications to be shown in the UI
 */
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _JNotifications_onNotificationCallbacks, _JNotifications_onWarningCallbacks;
class JNotifications {
    constructor() {
        _JNotifications_onNotificationCallbacks.set(this, void 0);
        _JNotifications_onWarningCallbacks.set(this, void 0);
        this.init = () => { };
        this.onNotification = (callback) => __classPrivateFieldGet(this, _JNotifications_onNotificationCallbacks, "f").add(callback);
        this.offNotification = (callback) => __classPrivateFieldGet(this, _JNotifications_onNotificationCallbacks, "f").delete(callback);
        this.onWarning = (callback) => __classPrivateFieldGet(this, _JNotifications_onWarningCallbacks, "f").add(callback);
        this.offWarning = (callback) => __classPrivateFieldGet(this, _JNotifications_onWarningCallbacks, "f").delete(callback);
        this.showNotification = (message) => {
            console.log(message);
            __classPrivateFieldGet(this, _JNotifications_onNotificationCallbacks, "f").forEach((callback) => callback(message));
        };
        this.showWarning = (message) => {
            console.warn(message);
            __classPrivateFieldGet(this, _JNotifications_onWarningCallbacks, "f").forEach((callback) => callback(message));
        };
        __classPrivateFieldSet(this, _JNotifications_onNotificationCallbacks, new Set(), "f");
        __classPrivateFieldSet(this, _JNotifications_onWarningCallbacks, new Set(), "f");
    }
    ;
}
_JNotifications_onNotificationCallbacks = new WeakMap(), _JNotifications_onWarningCallbacks = new WeakMap();
;
export default JNotifications;
