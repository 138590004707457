import CourseService from "./courseService";
import HttpService from "jhttp-client";
import NotificationService from "jnotifications";
import UserService from "./userService";
import translations from "../static/translations";
import J18Next from "j18next";
import LanguageDetector from 'i18next-browser-languagedetector';



class JCourses {
	#courses:CourseService;
	#http:HttpService;
	#localization:J18Next;
	#notifications:NotificationService;
	#users:UserService;

	get courses() {return this.#courses}
	get notifications() {return this.#notifications}

	constructor() {
		this.#courses = new CourseService();
		this.#http = new HttpService();
		this.#localization = new J18Next(translations, LanguageDetector);
		this.#notifications = new NotificationService();
		this.#users = new UserService();
	}

	init = async() => {
		await this.#localization.init({
			detection:{ order:[ "localStorage","htmlTag" ] },
		});
		this.#localization.setLanguage("fi"); //TMP
		this.#notifications.init();
		this.#http.init();
		this.#users.init(this.#http);
		//this.#courses.init(this.#http,this.#users);
		this.#courses.init(this.#http, this.#notifications);
	};

	get user() {return this.#users.user}
	get language() {return this.#localization.language}
	get languages() {return this.#localization.languages}
	get termsAccepted() {return this.#users.termsAccepted}

	registerAndLogin = (userData:{ email:string,name:string,password:string }) => this.#users.registerAndLogin(userData);
	login = (userData:{ email:string, password:string }) => this.#users.login(userData);
	logout = () => this.#users.logout();
	onUserChanged = (callback:any) => this.#users.onUserChanged(callback);
	offUserChanged = (callback:any) => this.#users.offUserChanged(callback);
	setTermsAcceptedTrue = () => this.#users.setTermsAccepted(true);
	onTermsAccepted = (callback:any) => this.#users.onTermsAccepted(callback);
	offTermsAccepted = (callback:any) => this.#users.offTermsAccepted(callback);

	setLanguage = (language:string) => this.#localization.setLanguage(language);
	onLanguageChanged = (callback:any) => this.#localization.onLanguageChanged(callback);
	offLanguageChanged = (callback:any) => this.#localization.offLanguageChanged(callback);
	translate = (text:string) => this.#localization.translate(text);
}



export default JCourses;
