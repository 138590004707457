var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, privateMap, value) {
    if (!privateMap.has(receiver)) {
        throw new TypeError("attempted to set private field on non-instance");
    }
    privateMap.set(receiver, value);
    return value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, privateMap) {
    if (!privateMap.has(receiver)) {
        throw new TypeError("attempted to get private field on non-instance");
    }
    return privateMap.get(receiver);
};
var _number, _assignment, _help, _rightAnswer, _solution;
import JJoi from "jjoi";
const coursePartExerciseObjectSchema = JJoi.object().required().keys({
    n: JJoi.string().required(),
    a: JJoi.string().required(),
    h: JJoi.string().required().allow(""),
    r: JJoi.string().required().allow(""),
    s: JJoi.string().required().allow(""),
});
class CoursePartExercise {
    constructor(coursePartExerciseObject) {
        _number.set(this, void 0);
        _assignment.set(this, void 0); // all following are html strings
        _help.set(this, void 0);
        _rightAnswer.set(this, void 0);
        _solution.set(this, void 0);
        JJoi.assert(coursePartExerciseObject, coursePartExerciseObjectSchema);
        const { n, a, h, r, s } = coursePartExerciseObject;
        __classPrivateFieldSet(this, _number, n);
        __classPrivateFieldSet(this, _assignment, a);
        __classPrivateFieldSet(this, _help, h);
        __classPrivateFieldSet(this, _rightAnswer, r);
        __classPrivateFieldSet(this, _solution, s);
    }
    get number() { return __classPrivateFieldGet(this, _number); }
    get assignment() { return __classPrivateFieldGet(this, _assignment); }
    get help() { return __classPrivateFieldGet(this, _help); }
    get rightAnswer() { return __classPrivateFieldGet(this, _rightAnswer); }
    get solution() { return __classPrivateFieldGet(this, _solution); }
}
_number = new WeakMap(), _assignment = new WeakMap(), _help = new WeakMap(), _rightAnswer = new WeakMap(), _solution = new WeakMap();
export default CoursePartExercise;
