import { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { AppLayoutStyleContext, NavbarContext, SidebarShowContext } from '../../../Contexts';
import { useUser, courses } from '../../../jCoursesReact';
import { Course as Coursee, noCourse, CourseData, CourseDataIntro, CourseDataChapter, CourseDataSection, CourseDataPart, CoursePartTheory, CoursePartExercise, emptyCourse } from 'jcourses';



const Course = () => {
	const coursePlayerRef = useRef<HTMLDivElement>(null);
	const { _id } = useParams();
	const user = useUser();
	const { enableSideToggler, disableSideToggler } = useContext(NavbarContext)
	const showSidebar = useContext(SidebarShowContext);
	const layoutStyle = useContext(AppLayoutStyleContext);
	const [course, setCourse] = useState<Coursee>(noCourse);
	const [courseData, setCourseData] = useState<CourseData>(emptyCourse);
	const [chapter, setChapter] = useState<CourseDataChapter | CourseDataIntro>(emptyCourse.intro);
	const [scrollTo, setScrollTo] = useState<string>("");

	const fetchContent = async () => {
		const course = await courses.getCourse(_id || "");
		if (!course) return;
		setCourse(course);
		try {
			const courseData = await courses.getCourseData(course);
			setCourseData(courseData);
			setChapter(courseData.intro || courseData.chapters[0]);
		} catch (error) {
			console.log(error)
		}
	};

	const setTarget = (_chapter: CourseDataChapter | CourseDataIntro, scrollTo: string) => {
		if (_chapter === chapter) {
			doScroll(scrollTo);
		} else {
			setScrollTo(scrollTo);
			setChapter(_chapter);
		}
	};

	const doScroll = (scrollTo: string) => {
		const toel = document.getElementById(scrollTo);
		const topPos = toel?.offsetTop || 0;
		if (coursePlayerRef.current) {
			coursePlayerRef.current.scrollTop = topPos;
		}
	}

	useEffect(() => {
		fetchContent();
	}, [user]);

	useEffect(() => {
		enableSideToggler(true);
		return () => { disableSideToggler(false) }
	}, [])

	useLayoutEffect(() => {
		setTimeout(() => doScroll(scrollTo), 300);
	}, [chapter])

	return (
		<>
			{(layoutStyle === "large" || showSidebar) && <TableOfContents title={course.name} intro={courseData.intro} chapters={courseData.chapters} setTarget={setTarget} />}
			<CoursePlayer coursePlayerRef={coursePlayerRef} course={course} content={chapter} />
		</>
	);
};



type tableOfContentsPropTypes = {
	title: string;
	intro: CourseDataIntro;
	chapters: CourseDataChapter[];
	setTarget: Function;
}

const TableOfContents = ({ title, intro, chapters, setTarget }: tableOfContentsPropTypes) => {
	const layoutStyle = useContext(AppLayoutStyleContext);

	return (
		<div className={`courses-course-toc courses-course-toc-${layoutStyle}`}>
			<ul>
				<li className="courses-course-toc-item">
					{intro ?
						<NavLink to="#" className="text-reset" onClick={() => setTarget(intro, "")} style={{ display: "block" }}>
							{title}
						</NavLink>
						:
						title
					}
				</li>
				{chapters.map((chapter) => (
					<li key={chapter._id} className="courses-course-toc-item">
						<NavLink to="#" className="text-reset" onClick={() => setTarget(chapter, "")} style={{ display: "block" }}>
							{chapter.header}
						</NavLink>
						{chapter.sections.length > 0 ?
							<ul className="courses-course-toc-sections">
								{chapter.sections.map((section) => (
									<li className="courses-course-toc-sections-item" key={section._id}>
										<NavLink to="#" className="text-reset" onClick={() => setTarget(chapter, section._id)} style={{ display: "block" }}>
											{section.header}
										</NavLink>
									</li>
								))}
							</ul>
							:
							null
						}
					</li>
				))}
			</ul>
		</div>
	);
};



type coursePlayerPropTypes = {
	coursePlayerRef: any;
	course: Coursee;
	content: CourseDataChapter | CourseDataIntro | null;
}

const CoursePlayer = ({ coursePlayerRef, course, content }: coursePlayerPropTypes) => {
	const layoutStyle = useContext(AppLayoutStyleContext);

	return (
		<div ref={coursePlayerRef} className={`courses-course-chapter courses-course-chapter-${layoutStyle}`}>
			<CoursePlayerContent course={course} content={content} />
		</div>
	)
}



type coursePlayerContentPropTypes = {
	course: Coursee;
	content: CourseDataChapter | CourseDataIntro | null;
}

const CoursePlayerContent = ({ course, content }: coursePlayerContentPropTypes) => {
	if (content instanceof CourseDataChapter) return <Chapter chapter={content} course={course} />
	if (content instanceof CourseDataIntro) return <Intro intro={content} course={course} />
	throw Error("Lol")
}


type chapterPropTypes = {
	course: Coursee;
	chapter: CourseDataChapter;
}

const Chapter = ({ chapter, course }: chapterPropTypes) => {
	const { _id, header, intro, sections } = chapter;

	return (
		<>
			<h1 id={_id}>{header}</h1>
			{intro ? intro.parts.map((part) => <Item key={part._id} item={part} course={course} />) : null}
			{sections.map((section) => <Section key={section._id} section={section} course={course} />)}
		</>
	);
};



type introPropTypes = {
	course: Coursee;
	intro: CourseDataIntro;
}

const Intro = ({ course, intro }: introPropTypes) => {
	const { _id, parts } = intro;

	return (
		<>
			<h1 id={_id}>{course.name}</h1>
			{parts.map((part) => <Item key={part._id} item={part} course={course} />)}
		</>
	);
};



type sectionPropTypes = {
	course: Coursee;
	section: CourseDataSection;
}

const Section = ({ section, course }: sectionPropTypes) => {
	const layoutStyle = useContext(AppLayoutStyleContext);
	const { _id, header, parts } = section;

	return (
		<div className={`courses-course-section courses-course-section-${layoutStyle}`}>
			<h2 id={_id}>{header}</h2>
			{parts.map((part) => <Item key={part._id} item={part} course={course} />)}
		</div>
	);
};



type itemPropTypes = {
	course: Coursee;
	item: CourseDataPart;
}

const Item = ({ item, course }: itemPropTypes) => {
	switch (item.type) {
		case "t": return <Theory item={item} course={course} />;
		case "a": return <Exercise item={item} course={course} />;
		default: throw new Error("Invalid item type")
	}
};



const Theory = ({ item, course }: itemPropTypes) => {
	const [itemData, setItemData] = useState<CoursePartTheory | null>(null);

	const fetchItem = async () => {
		const itemData = await courses.getCourseTheory(course, item);
		setItemData(itemData)
	}

	useEffect(() => { fetchItem() }, [])

	if (!itemData) return null;
	return <div className="madtheory" dangerouslySetInnerHTML={{ __html: itemData.theory }} />
};



const Exercise = ({ item, course }: itemPropTypes) => {
	const [itemData, setItemData] = useState<CoursePartExercise | null>(null);

	const fetchItem = async () => {
		const itemData = await courses.getCourseExercise(course, item);
		setItemData(itemData)
	}

	useEffect(() => { fetchItem() }, [])

	if (!itemData) return null;
	return (
		<div className="madexercise">
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<span>Tehtävä</span>
				<span>{itemData.number}</span>
			</div>
			<div className="madexercise-a" dangerouslySetInnerHTML={{ __html: itemData.assignment }} />
			{itemData.help ? <ExerciseHelp html={itemData.help} /> : null}
			{itemData.rightAnswer ? <ExerciseRightAnswer html={itemData.rightAnswer} /> : null}
			{itemData.solution ? <ExerciseSolution html={itemData.solution} /> : null}

		</div>
	)
};



const ExerciseHelp = ({ html }: any) => {
	const [show, setShow] = useState<boolean>(false);

	const toggleShow = () => setShow(!show)

	return (
		<>
			<hr />
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<span>Vihje</span>
				<button onClick={toggleShow}>{show ? "Piilota" : "Näytä"}</button>
			</div>
			{show ? <div className="madexercise-h" dangerouslySetInnerHTML={{ __html: html }} /> : null}
		</>
	)
}

const ExerciseRightAnswer = ({ html }: any) => {
	const [show, setShow] = useState<boolean>(false);

	const toggleShow = () => setShow(!show)

	return (
		<>
			<hr />
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<span>Oikea vastaus</span>
				<button onClick={toggleShow}>{show ? "Piilota" : "Näytä"}</button>
			</div>
			{show ? <div className="madexercise-r" dangerouslySetInnerHTML={{ __html: html }} /> : null}
		</>
	)
}

const ExerciseSolution = ({ html }: any) => {
	const [show, setShow] = useState<boolean>(false);

	const toggleShow = () => setShow(!show)

	return (
		<>
			<hr />
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<span>Malliratkaisu</span>
				<button onClick={toggleShow}>{show ? "Piilota" : "Näytä"}</button>
			</div>
			{show ? <div className="madexercise-s" dangerouslySetInnerHTML={{ __html: html }} /> : null}
		</>
	)
}

export default Course;
