//import { User } from "juser";
import { Course,CourseData,CourseDataPart,CoursePartExercise,CoursePartTheory } from "jcourses";
import HttpService from "jhttp-client";
import type NotificationService from "jnotifications";
//import UserService from "./userService";



// courses and userCourses use _id (mongo id)
// courseData and courseItems use courseId --- own id convention

class CourseService {
	#httpService:HttpService;
	#notificationService:NotificationService;
	//#userService:UserService;

	#updateInterval:number;
	#lastUpdated:number; // local time point
	#coursesLastUpdated:number; // server time point
	#courses:{ [key:string]:Course };
	//#userCourses:Map<string, Course>;

	#courseData:{ [key:string]:CourseData };
	#courseExercises:{ [key:string]:CoursePartExercise };
	#courseTheories:{ [key:string]:CoursePartTheory };

	constructor() {
		this.#courses = {};
		//this.#userCourses = new Map();
		this.#courseData = {};
		this.#courseExercises = {};
		this.#courseTheories = {};
	}

	//init = (httpService:HttpService,userService:UserService) => {
	init = async(httpService:HttpService,notificationService:NotificationService) => {
		this.#updateInterval = 600_000;
		this.#lastUpdated = 0; // local time point
		this.#coursesLastUpdated = 0; // server time point
		this.#httpService = httpService;
		this.#notificationService = notificationService;
		//this.#userService = userService;
		//this.#userService.onUserChanged((user: User) => this.updateUserCourses())
	};

	maybeUpdate = async() => {
		if(Date.now() - this.#lastUpdated > this.#updateInterval) {
			//await this.update();
			await this.updateTmp();
		}
	}

	update = async() => {
		const [ response,error ] = await this.#httpService.get("api/courses/lastupdated");
		if(!response) {
			this.#notificationService.showNotification("Couldn't update courses. Internet connection or server is unavailable.")
			return;
		}
		if(error) throw error;
		// @ts-ignore
		const coursesLastUpdated = response.data.coursesLastUpdated;
		if(this.#coursesLastUpdated >= coursesLastUpdated) {
			this.#lastUpdated = Date.now();
			return; // no need to update
		}

		const [ response2,error2 ] = await this.#httpService.get("api/courses/catalog");
		if(!response) {
			this.#notificationService.showNotification("Couldn't update courses. Internet connection or server is unavailable.")
			return;
		}
		if(error2) throw error;
		// @ts-ignore
		for(const course of response2.data) {
			this.#courses[course._id] = new Course(course);
		}
		
		this.#coursesLastUpdated = coursesLastUpdated;
		this.#lastUpdated = Date.now();
	};

	updateTmp = async() => {
		const [ response,error ] = await this.#httpService.get("assets/courses.json");
		if(!response) {
			this.#notificationService.showNotification("Couldn't update courses. Internet connection or server is unavailable.")
			return;
		}
		if(error) throw error;
		// @ts-ignore
		const coursesLastUpdated = response.data.coursesLastUpdated;
		if(this.#coursesLastUpdated >= coursesLastUpdated) {
			this.#lastUpdated = Date.now();
			return; // no need to update
		}

		for(const course of response.data.catalog) {
			this.#courses[course._id] = new Course(course);
		}
		
		this.#coursesLastUpdated = coursesLastUpdated;
		this.#lastUpdated = Date.now();
	};

	/*
	updateUserCourses = async() => {
		await this.maybeUpdate();
		const sessionToken = this.#userService.sessionToken;
		if (!sessionToken) {
			this.clearUserCourses();
			throw new Error("No user logged in");
		};
		// @ts-ignore
		let data;
		try {
			const _res = await this.#httpService.get("/api/courses/my");
			data = _res && _res.data;
		} catch(error) {return}
		this.clearUserCourses();
		for (const course_id of data) {
			// @ts-ignore
			this.#userCourses.set(course_id,this.#courses.get(course_id));
		}
	}

	clearUserCourses = () => {
		this.#userCourses.clear();
	}
	*/

	// --> Getting course thumbnails
	getCourseCatalog = async() => {
		await this.maybeUpdate();
		return Object.values(this.#courses);
	};

	/*
	getUserCourses = async() => {
		//console.log("getUserCourses")
		await this.updateUserCourses();
		return this.#userCourses;
	}
	*/

	getCourse = async(course_id:string) => {
		await this.maybeUpdate();
		return this.#courses[course_id]
	}
	// <-- Getting course thumbnails

	// --> 
	// courseData and courseItems use courseId --- own id convention
	getCourseData = async(course:Course) => {
		//if (!this.#userService.sessionToken) throw new Error("No user logged in");
		const { _id } = course;
		if(!(_id in this.#courseData))
			await this.#fetchAndCacheCourseData(_id)
		return this.#courseData[_id];
	}

	#fetchAndCacheCourseData = async(courseId:string) => {
		//console.log("#fetchAndCacheCourseData");
		// @ts-ignore
		const [ response,error ] = await this.#httpService.get(`/assets/courses/${courseId}/${courseId}.json`);
		if(!response) return;
		if(error) throw error;
		this.#courseData[courseId] = new CourseData(response.data);
	};

	getCourseExercise = async(course:Course,item:CourseDataPart) => {
		//if (!this.#userService.sessionToken) throw new Error("No user logged in");
		const { _id } = item // _id could be renamed to itemId
		// @ts-ignore
		if(!(_id in this.#courseExercises))
			await this.#fetchAndCacheCourseExercise(course._id,_id)
		return this.#courseExercises[_id];
	}
	
	#fetchAndCacheCourseExercise = async(courseId:string,itemId:string) => {
		//console.log("#fetchAndCacheCourseItem", itemId)
		// @ts-ignore
		const [ response,error ] = await this.#httpService.get(`/assets/courses/${courseId}/${itemId}.json`);
		if(!response) return;
		if(error) throw error;
		this.#courseExercises[itemId] = new CoursePartExercise(response.data);
	};

	getCourseTheory = async(course:Course,item:CourseDataPart) => {
		//if (!this.#userService.sessionToken) throw new Error("No user logged in");
		const { _id } = item // _id could be renamed to itemId
		// @ts-ignore
		if(!(_id in this.#courseTheories))
			await this.#fetchAndCacheCourseTheory(course._id,_id)
		return this.#courseTheories[_id];
	}

	#fetchAndCacheCourseTheory = async(courseId:string,itemId:string) => {
		//console.log("#fetchAndCacheCourseItem", itemId)
		// @ts-ignore
		const [ response,error ] = await this.#httpService.get(`/assets/courses/${courseId}/${itemId}.json`);
		if(!response) return;
		if(error) throw error;
		this.#courseTheories[itemId] = new CoursePartTheory(response.data);;
	};
	// <--


	/*
	// now course = course._id
	// communicate with userService or something that caches user specific stuff
	addCourseToUser = async(course:Course) => {
		const sessionToken = this.#userService.sessionToken;
		if (!sessionToken) throw new Error("No user logged in");
		// @ts-ignore
		await this.#httpService.put("/api/courses/my/add", { courseId:course._id }, { headers: { "x-auth-token": sessionToken } });
		await this.updateUserCourses();
	};

	isMine = (course: Course) => this.#userCourses.has(course._id);
	*/
};



export default CourseService;
