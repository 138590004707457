import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useUser, t, courses } from '../../../jCoursesReact';
import Table from "../../../common/Table";
//import BuyOrAddButton from './BuyOrAddButton';



const Catalog = () => {
	const user = useUser();
	const [_courses, setCourses] = useState<any[]>([]);
	const [headers, setHeaders] = useState<any[]>([]);
	const [failed, setFailed] = useState<boolean>(false);

	const fetchCourses = async () => {
		const headers = [{ name: "", label: "icon" }, { name: t("Course"), label: "name" }];
		//if(user._id) headers.push({name:"",label:"buyOrOpen"});
		setHeaders(headers);
		const courseCatalog = await courses.getCourseCatalog();
		setFailed(courseCatalog.length === 0)
		// @ts-ignore
		const courseItems = courseCatalog.map((course) => ({
			_id: course._id,
			name: <NavLink to={`/app/course/${course._id}`}>{course.name}</NavLink>,
			icon: <img src={`/media/${course.iconFileName}`} height="50" width="50" alt="course icon" />,
			//buyOrOpen : <></>,
		}));
		setCourses(courseItems);
	};

	useEffect(() => {
		fetchCourses();
	}, [user])

	return (
		<div className="courses-catalog">
			<h1>{t("Courses")}</h1>
			{failed ? <FailPage fetchCourses={fetchCourses} /> : <Table headers={headers} data={_courses} />}
		</div>
	);
};



type failPagePT = {
	fetchCourses: Function;
}

const FailPage = ({ fetchCourses }: failPagePT) => {
	useEffect(() => {
		const x = setInterval(fetchCourses, 60_000);
		return () => clearInterval(x);
	}, []);

	return (
		<>
			<p>Kurssien lataaminen epäonnistui</p>
			<button onClick={() => fetchCourses()}>Yritä uudestaan</button>
		</>
	);
}

export default Catalog;

/*

*/
